import {
  Box,
  Button,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  height,
  left,
  marginBottom,
  marginTop,
  match,
  paddingBottom,
  paddingTop,
  position,
  right,
  top,
  width,
  zIndex,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import ImgInvestTest from './image-invest-text.svg';
import ImgSpaceshipLarge from './image-spaceship-large.svg';
import ImgSpaceshipSmall from './image-spaceship-small.svg';

const rotateInvestInYourFuture = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Rocket = styled.div`
  ${height({ xs: 262, md: 385, lg: 635 })}
  ${left({ md: '50%' })}
  ${position({ md: 'absolute' })}
  ${top({ md: '50%' })}
  ${width({ xs: 262, md: 300, lg: 495 })}
  background-image: url(${ImgSpaceshipSmall});
  background-repeat: no-repeat;
  background-size: cover;

  ${match('md')`
    background-image: url(${ImgSpaceshipLarge});
    transform: translate(-50%, -50%);
  `}
`;

const InvestInYourFuture = styled.div`
  ${height({ xs: 180, lg: 900 })}
  ${left({ xs: '100%', md: 'calc(100% - 75px)', lg: 0 })}
  ${top({ xs: '-16px', md: 'calc(100% - 120px)', lg: 0 })}
  ${width({ xs: 180, lg: 900 })}
  animation: ${rotateInvestInYourFuture} 60s linear infinite;
  background-image: url(${ImgInvestTest});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;

  :first-child {
    left: -70%;
    top: 35%;

    ${match('md')`
      display: none;
    `}
  }
`;

const Images = styled.div`
  ${height({ xs: 262, md: 385, lg: 900 })}
  ${left({ md: '73%', lg: 'auto' })}
  ${marginBottom({ xs: 'xxs', md: 'none' })}
  ${marginTop({ xs: 'md', md: 'none' })}
  ${position({ xs: 'relative', md: 'absolute' })}
  ${right({ md: 'auto', lg: '-208px' })}
  ${top({ md: '50%', lg: '-120px' })}
  ${width({ xs: 262, md: 300, lg: 900 })}
  margin-left: auto;
  margin-right: auto;

  ${match('md')`
    transform: translate(-50%, -50%);
  `}

  ${match('lg')`
    transform: none;
  `}
`;

const HeaderContainer = styled.div`
  ${zIndex('max')}
  position: relative;
`;

const HeroTextContainer = styled.div`
  ${paddingTop({ lg: 'xl' })}
  ${paddingBottom({ lg: 'xxl' })}
  ${zIndex({ lg: 1 })}
  position: relative;
`;

export interface HeroPanelProps {
  title: string;
  description: string;
}

// TODO: Convert to use reverse from Columns once implementation is added to @spaceship-fspl/substance
export const HeroPanel: React.FC<React.PropsWithChildren<HeroPanelProps>> = ({
  title,
  description,
  children,
}) => (
  <TrackingCategory category="Hero Panel">
    <HeaderContainer>{children}</HeaderContainer>

    <Container>
      <Box paddingTop={{ md: 'lg', lg: 'xxxl' }} position="relative">
        <Images>
          <InvestInYourFuture />
          <InvestInYourFuture />
          <Rocket />
        </Images>

        <Columns spaceY={{ xs: 'xxs', md: 'none' }}>
          <Columns.Column width={{ xs: 1, md: 3 / 8, lg: 4 / 12 }}>
            <HeroTextContainer>
              <Stack spaceY="lg" alignX={{ xs: 'center', md: 'left' }}>
                <Stack spaceY="md">
                  <Heading
                    variant={1}
                    color="neutral.100"
                    align={{
                      xs: 'center',
                      md: 'left',
                    }}
                    component="h1"
                  >
                    {title}
                  </Heading>
                  <Text
                    variant={1}
                    color="neutral.080"
                    align={{
                      xs: 'center',
                      md: 'left',
                    }}
                  >
                    {description}
                  </Text>
                </Stack>
                <Button
                  href={ExternalRoutes.SPACESHIP_SIGNUP}
                  size="lg"
                  variant="primary"
                >
                  Sign up
                </Button>
              </Stack>
            </HeroTextContainer>
          </Columns.Column>
        </Columns>
      </Box>
    </Container>
  </TrackingCategory>
);
