import {
  Container,
  Heading,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { Media } from '@spaceship-fspl/styles';
import { ResponsiveValue } from '@spaceship-fspl/substance-style';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import { PostsOrPages } from '@tryghost/content-api';
import { ArticleCard, PanelSection, ScrollableArticleCards } from 'components';
import Link from 'next/link';
import React from 'react';

export interface LearnPanelProps {
  title?: string | null;
  description?: string | null;
  posts: PostsOrPages;
  columns?: ResponsiveValue<Media, number>;
  hasMore?: boolean;
}

export const LearnPanel: React.FC<React.PropsWithChildren<LearnPanelProps>> = ({
  title,
  description,
  posts,
  columns,
  hasMore = true,
}) => {
  return (
    <TrackingCategory category="Learn Panel">
      <PanelSection backgroundColor="neutral.015">
        <Container>
          <Stack spaceY={{ xs: 'lg', md: 'xl' }}>
            <Stack spaceY="sm">
              <Heading variant={2} align="center" color="neutral.100">
                {title}
              </Heading>
              {description ? (
                <Text variant={1} align="center" color="neutral.085">
                  {description}
                </Text>
              ) : null}
            </Stack>
            <Stack spaceY="xxs">
              <ScrollableArticleCards columns={columns}>
                {posts.map(
                  ({
                    title,
                    slug,
                    feature_image,
                    excerpt,
                    primary_author,
                    reading_time,
                    primary_tag,
                  }) => (
                    <ArticleCard
                      key={slug}
                      slug={slug}
                      title={title}
                      image={feature_image}
                      tag={primary_tag}
                      description={excerpt}
                      author={{
                        ...primary_author,
                        profileImage: primary_author?.profile_image,
                      }}
                      readingTime={reading_time}
                    />
                  ),
                )}
              </ScrollableArticleCards>
              {hasMore ? (
                <Text variant={2} align="center" component="div">
                  <Link href="/learn/" passHref legacyBehavior>
                    <PresentationLink size="md" icon="arrow">
                      Explore more
                    </PresentationLink>
                  </Link>
                </Text>
              ) : null}
            </Stack>
          </Stack>
        </Container>
      </PanelSection>
    </TrackingCategory>
  );
};
