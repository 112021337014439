import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { formatDate } from '@spaceship-fspl/helpers';
import {
  alignItems,
  backgroundColor,
  borderRadius,
  display,
  flex,
  flexDirection,
  getSpace,
  height,
  justifyContent,
  left,
  linearGradient,
  marginLeft,
  marginTop,
  match,
  padding,
  paddingTop,
  position,
  top,
  width,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import React from 'react';
import styled from 'styled-components';

import quoteBackgroundImg from './image-background.svg';

const BOX_WIDTH_XL = 368;
const BOX_SPACING_XL = 'lg';

const BOX_WIDTH_MD = 294;
const BOX_SPACING_MD = 'md';

const ReviewsPanelContainer = styled.div`
  ${alignItems({ xs: 'center', md: 'flex-end', lg: 'center' })}
  ${flexDirection({ xs: 'column', md: 'row-reverse' })}
  ${justifyContent({ md: 'space-between' })}
  ${height({ md: 680, lg: 'auto' })}
  display: flex;
  position: relative;
`;

const ReviewsContainer = styled.div`
  ${flex({ lg: 2 / 3 })}
  ${position({ md: 'absolute', lg: 'relative' })}
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const BackgroundImage = styled.div`
  ${height({ lg: 630 })}
  ${paddingTop({ lg: 'lg' })}
  ${width({ md: '100%' })}
  background-image: url(${quoteBackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;

  ${match('md')`
    display: block;
    transform: scaleY(-1);
  `}

  ${match('lg')`
    transform: none;
  `}
`;

const ReviewBox = styled.div`
  ${backgroundColor('neutral.015')}
  ${borderRadius('sm')}
  ${marginLeft({ lg: 'xxl', xl: 'xxxl' })}
  ${marginTop({ xs: 'sm', md: BOX_SPACING_MD, xl: BOX_SPACING_XL })}
  ${padding({ xs: 'md', xl: 'xl' })}
  ${width({ xs: '100%', md: BOX_WIDTH_MD, xl: BOX_WIDTH_XL })}
  box-sizing: border-box;

  :first-child {
    ${marginLeft('none')}
    ${marginTop({ xs: 'md', md: 'none' })}
  }

  :nth-child(2) {
    ${left({
      lg: `calc(${BOX_WIDTH_MD}px + ${getSpace(BOX_SPACING_MD)})`,
      xl: `calc(${BOX_WIDTH_XL}px + ${getSpace(BOX_SPACING_XL)})`,
    })}
    ${marginLeft({ md: BOX_SPACING_MD, lg: 'none' })}
    ${marginTop({ md: 'none', xl: 'lg' })}
    ${position({ lg: 'absolute' })}
    ${top({ lg: getSpace('xxxl') })}

    ${match('lg')`
      background: ${linearGradient({
        angle: '225deg',
        colorStops: [
          ['neutral.015', '51.75%'],
          ['neutral.000', '117.2%'],
        ],
      })};
    `}
  }
`;

const ReviewCardsContainer = styled.div`
  ${display({ md: 'flex', lg: 'block' })}
  ${left({ lg: 'auto' })}
  ${top({ md: getSpace('xxxl'), lg: 0 })}
  ${position({ md: 'absolute', lg: 'relative' })}
  ${width({
    md: `calc(${BOX_WIDTH_MD * 2}px + ${getSpace(BOX_SPACING_MD)})`,
    xl: `calc(${BOX_WIDTH_XL * 2}px + ${getSpace(BOX_SPACING_XL)})`,
  })}

  ${match('md')`
    flex-flow: wrap;
  `}
`;

export interface ReviewCardProps {
  name: string;
  date: string;
  review: string;
}

export interface ReviewsPanelProps {
  reviews?: ReviewCardProps[];
}

const ReviewHeading: React.FC<React.PropsWithChildren> = () => (
  <Heading variant={2}>What they are saying</Heading>
);

const ReviewCard: React.FC<React.PropsWithChildren<ReviewCardProps>> = ({
  name,
  date,
  review,
}) => (
  <ReviewBox>
    <Stack spaceY={{ xs: 'xxs', md: 'sm' }}>
      <Text variant={2}>
        {name} - {formatDate(new Date(date), 'MMMM yyyy')}
      </Text>
      <Text variant={1} color="neutral.085">
        {review}
      </Text>
    </Stack>
  </ReviewBox>
);

export const ReviewsPanel: React.FC<
  React.PropsWithChildren<ReviewsPanelProps>
> = ({ reviews }) => {
  const firstReview = reviews?.[0];
  const secondReview = reviews?.[1];
  const thirdReview = reviews?.[2];

  if (!firstReview || !secondReview || !thirdReview) {
    return null;
  }

  return (
    <TrackingCategory category="Reviews Panel">
      <Container>
        <ReviewsPanelContainer>
          <Box flex={{ xs: 1, md: 1 / 2, lg: 1 / 4 }}>
            <Visible isHidden={{ md: true, lg: false }}>
              <ReviewHeading />
            </Visible>
          </Box>

          <ReviewsContainer>
            <BackgroundImage />

            <ReviewCardsContainer>
              <ReviewCard {...firstReview} />
              <ReviewCard {...secondReview} />
              <ReviewCard {...thirdReview} />

              <Visible isHidden={{ xs: true, md: false, lg: true }}>
                <Box
                  marginLeft={BOX_SPACING_MD}
                  marginTop={BOX_SPACING_MD}
                  width={BOX_WIDTH_MD}
                >
                  <ReviewHeading />
                </Box>
              </Visible>
            </ReviewCardsContainer>
          </ReviewsContainer>
        </ReviewsPanelContainer>
      </Container>
    </TrackingCategory>
  );
};
