import {
  Container,
  Divider,
  Stack,
  Text,
  Tiles,
} from '@spaceship-fspl/components';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import { PanelSection } from 'components';
import { AppAndPlayStores } from 'components/app-and-play-stores';
import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  max-width: 100%;
  height: 64px;
`;

export interface FeaturesPanelProps {
  features: {
    text: React.ReactNode;
    icon: {
      url: string;
      alt: string;
    };
  }[];
  playStoreUrl: string | null | undefined;
  appStoreUrl: string | null | undefined;
}

export const FeaturesPanel: React.FC<
  React.PropsWithChildren<FeaturesPanelProps>
> = ({ features, playStoreUrl, appStoreUrl }) => (
  <TrackingCategory category="Features Panel">
    <PanelSection>
      <Container>
        <Stack spaceY="lg">
          <Tiles
            columns={{ xs: 1, md: 3 }}
            alignX="center"
            spaceX="sm"
            spaceY="sm"
          >
            {features.map((feature, index) => (
              <Stack key={index} spaceY="md" alignX="center">
                <Image src={feature.icon.url} alt={feature.icon.alt} />
                <Text variant={1} component="div" align="center">
                  {feature.text}
                </Text>
              </Stack>
            ))}
          </Tiles>
          <AppAndPlayStores
            playStoreUrl={playStoreUrl}
            appStoreUrl={appStoreUrl}
          />
        </Stack>
      </Container>
    </PanelSection>
    <Divider color="neutral.050" />
  </TrackingCategory>
);
