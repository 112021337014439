import { Inline, UnstyledLink } from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import { height, width } from '@spaceship-fspl/styles';
import React from 'react';
import styled, { css } from 'styled-components';

import badgeAppleAppStore from './badge-apple-app-store.svg';
import badgeAppleAppStoreLight from './badge-apple-app-store-light.svg';
import badgeGooglePlayStore from './badge-google-play-store.svg';
import badgeGooglePlayStoreLight from './badge-google-play-store-light.svg';

const StoreLinkWrapper = styled(UnstyledLink)`
  display: flex;
  justify-content: center;
`;

const StoreImage = styled.img<{ variant: AppAndPlayStoresVariant }>`
  ${({ variant }) => {
    if (variant === 'light') {
      return css`
        ${height({ xs: 'auto', md: '55px' })};
        ${width({ xs: '183px', md: 'auto' })};
      `;
    }

    return css`
      ${height({ xs: 'auto', md: '64px' })};
      ${width({ xs: '188px', md: 'auto' })};
    `;
  }}
`;

export type AppAndPlayStoresVariant = 'light' | 'dark';

export interface AppAndPlayStoresProps {
  variant?: 'light' | 'dark';
  playStoreUrl: string | null | undefined;
  appStoreUrl: string | null | undefined;
}

export const AppAndPlayStores: React.FC<
  React.PropsWithChildren<AppAndPlayStoresProps>
> = ({ variant = 'dark', playStoreUrl, appStoreUrl }) => {
  return (
    <Inline alignX="center" spaceX="md" spaceY="md">
      <StoreLinkWrapper
        href={playStoreUrl ?? ExternalRoutes.FALLBACK_APP_STORE_GOOGLE_PLAY}
        rel="noreferrer"
        target="_blank"
      >
        <StoreImage
          src={
            variant === 'dark'
              ? badgeGooglePlayStore
              : badgeGooglePlayStoreLight
          }
          alt="Google play store"
          variant={variant}
        />
      </StoreLinkWrapper>

      <StoreLinkWrapper
        href={appStoreUrl ?? ExternalRoutes.FALLBACK_APP_STORE_IOS}
        rel="noreferrer"
        target="_blank"
      >
        <StoreImage
          src={
            variant === 'dark' ? badgeAppleAppStore : badgeAppleAppStoreLight
          }
          alt="Apple app store"
          variant={variant}
        />
      </StoreLinkWrapper>
    </Inline>
  );
};
