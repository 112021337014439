import {
  Box,
  Button,
  Columns,
  Container,
  Heading,
  Inline,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { marginTop, match, paddingLeft } from '@spaceship-fspl/styles';
import { PanelSection } from 'components';
import * as React from 'react';
import styled from 'styled-components';

import novaImage from './nova.png';
import listCheckImage from './streamline-check-1.svg';
import superImage from './super.png';
import voyagerImage from './voyager.png';

interface ProductsPanelProps {
  products: Array<{
    name: string;
    title: string;
    description: React.ReactNode;
    signupButtonText?: string;
    signupButtonUrl?: string;
    signupButtonTarget?: React.HTMLAttributeAnchorTarget;
  }>;
}

export const ProductsPanel: React.FC<ProductsPanelProps> = ({ products }) => {
  return (
    <PanelSection>
      <Container>
        <Stack spaceY={{ xs: 'xxl', xl: 'none' }}>
          {products.map((product, index) => {
            let image;
            let learnMoreUrl;
            let signupUrl;

            switch (product.name) {
              case 'voyager':
                image = <StyledProductImage src={voyagerImage} />;
                learnMoreUrl = '/voyager/';
                signupUrl = product.signupButtonUrl || learnMoreUrl;
                break;

              case 'super':
                image = <StyledProductImage src={superImage} />;
                learnMoreUrl = '/super/';
                signupUrl = product.signupButtonUrl || learnMoreUrl;
                break;

              case 'nova':
                image = <StyledProductImage src={novaImage} />;
                learnMoreUrl = '/us-investing/';
                signupUrl = product.signupButtonUrl || learnMoreUrl;
                break;
            }

            return (
              <Columns
                alignX="center"
                spaceX={{ md: 'md', lg: 'lg', xl: 'xxl' }}
                spaceY={{ xs: 'sm', md: 'none' }}
                reverse={{ md: index % 2 !== 0 }}
                key={product.name}
              >
                <Columns.Column width={{ md: 5 / 12, xl: 1 / 2 }}>
                  <StyledContentBox>{image}</StyledContentBox>
                </Columns.Column>

                <Columns.Column width={{ md: 7 / 12, xl: 1 / 2 }}>
                  <StyledContentBox>
                    <Stack spaceY={{ xs: 'xl', lg: 'xxl' }}>
                      <Stack spaceY="md">
                        <Heading
                          variant={2}
                          align={{ xs: 'center', md: 'left' }}
                        >
                          {product.title}
                        </Heading>

                        <StyledDescriptionText>
                          {product.description}
                        </StyledDescriptionText>
                      </Stack>

                      <Inline
                        spaceX="xxl"
                        spaceY="lg"
                        alignY="center"
                        alignX={{ xs: 'center', md: 'left' }}
                      >
                        {!!product.signupButtonText && !!signupUrl && (
                          <Button
                            size="lg"
                            variant="primary"
                            href={signupUrl}
                            target={product.signupButtonTarget}
                          >
                            {product.signupButtonText}
                          </Button>
                        )}

                        <PresentationLink
                          size="md"
                          icon="arrow"
                          href={learnMoreUrl}
                        >
                          Learn more
                        </PresentationLink>
                      </Inline>
                    </Stack>
                  </StyledContentBox>
                </Columns.Column>
              </Columns>
            );
          })}
        </Stack>
      </Container>
    </PanelSection>
  );
};

const StyledContentBox = styled(Box).attrs({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})``;

const StyledProductImage = styled.img`
  max-width: 100%;
  height: 300px;
  width: auto;
  object-fit: cover;

  ${match('md')`
    height: auto;
  `};
`;

const StyledDescriptionText = styled(Text).attrs({
  variant: 1,
  color: 'neutral.085',
  align: { xs: 'center', md: 'left' },
  component: 'div',
})`
  ul {
    margin: 0;
    ${marginTop('md')}
  }

  li {
    list-style-image: url(${listCheckImage});
    text-align: left;
    ${marginTop('sm')}
    ${paddingLeft('xs')}
  }
`;
