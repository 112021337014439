import { Box, Divider, Stack } from '@spaceship-fspl/components';
import { useAppStoreLinks } from '@spaceship-fspl/graphql';
import { PostsOrPages } from '@tryghost/content-api';
import {
  getHeroPageLayoutStaticProps,
  getMetadataPropsFromPrismicSlice,
  HeaderProps,
  mapNodeToHeaderProps,
  Metadata,
  MetadataProps,
  PanelSection,
  StickyHeader,
  withHeroPageLayout,
} from 'components';
import { LearnPanel } from 'components/learn-panel';
import { getPosts } from 'data/ghost/api-cached';
import {
  getHeader,
  getHomePage,
  mapSlicesToProps,
  RichText,
} from 'data/prismic';
import {
  _ExternalLink,
  HomepageBodyFeatures,
  HomepageBodyFeaturesFields,
  HomepageBodyHero,
  HomepageBodyHeroPrimary,
  HomepageBodyLearn,
  HomepageBodyLearnPrimary,
  HomepageBodyProducts,
  HomepageBodyProductsFields,
  HomepageBodyReviews,
  HomepageBodyReviewsFields,
} from 'data/prismic/types';
import { Link as PrismicLink } from 'prismic-reactjs';
import React, { HTMLAttributeAnchorTarget } from 'react';

import { FeaturesPanel } from './index/features-panel';
import { HeroPanel } from './index/hero-panel';
import { ProductsPanel } from './index/products-panel';
import { ReviewCardProps, ReviewsPanel } from './index/reviews-panel';

const POST_COUNT = 3;

interface HomePageProps {
  headerProps?: HeaderProps;
  prismicData: {
    HomepageBodyMetadata?: null | MetadataProps;
    HomepageBodyHero?: null | HomepageBodyHeroPrimary;
    HomepageBodyFeatures?: null | {
      features?: null | HomepageBodyFeaturesFields[];
    };
    HomepageBodyProducts?: HomepageBodyProductsFields[] | null;
    HomepageBodyLearn?: null | HomepageBodyLearnPrimary;
    HomepageBodyReviews?: null | {
      reviews?: null | HomepageBodyReviewsFields[];
    };
  };
  ghostData: {
    posts: PostsOrPages;
  };
  showSuperPanel?: boolean;
  showLearnPanel?: boolean;
}

export const getStaticProps = getHeroPageLayoutStaticProps<HomePageProps>(
  async () => {
    const header = await getHeader();
    const document = await getHomePage();
    const prismicData = mapSlicesToProps(document, {
      HomepageBodyMetadata: (slice) => getMetadataPropsFromPrismicSlice(slice),
      HomepageBodyHero: (slice: HomepageBodyHero) => ({
        ...slice.primary,
      }),
      HomepageBodyFeatures: (slice: HomepageBodyFeatures) => ({
        features: slice.fields,
      }),
      HomepageBodyProducts: (slice: HomepageBodyProducts) => slice.fields,
      HomepageBodyLearn: (slice: HomepageBodyLearn) => ({
        ...slice.primary,
      }),
      HomepageBodyReviews: (slice: HomepageBodyReviews) => ({
        reviews: slice.fields,
      }),
    });
    const posts = await getPosts({ limit: POST_COUNT });

    return {
      props: {
        headerProps: mapNodeToHeaderProps(header),
        prismicData,
        ghostData: {
          posts,
        },
      },
    };
  },
);

const HomePage: React.FC<React.PropsWithChildren<HomePageProps>> = ({
  headerProps,
  prismicData: {
    HomepageBodyMetadata: metadata,
    HomepageBodyHero: heroPanel,
    HomepageBodyFeatures: featuresPanel,
    HomepageBodyProducts: productsPanel,
    HomepageBodyReviews: reviewsPanel,
    HomepageBodyLearn: learnPanel,
  },
  ghostData: { posts },
  showSuperPanel = true,
  showLearnPanel = true,
}) => {
  const appStoreLinksResp = useAppStoreLinks();

  return (
    <Box overflow="hidden">
      {metadata && <Metadata {...metadata} />}

      <Stack spaceY={{ xs: 'none', lg: 'xxxl' }}>
        <Box as="section">
          <HeroPanel
            title={heroPanel?.title || ''}
            description={heroPanel?.description || ''}
          >
            <StickyHeader {...headerProps} />
          </HeroPanel>
        </Box>

        <FeaturesPanel
          features={
            featuresPanel && featuresPanel.features
              ? featuresPanel.features.map(({ text, icon: { url, alt } }) => ({
                  text: <RichText render={text} />,
                  icon: {
                    url: url || '',
                    alt: alt || '',
                  },
                }))
              : []
          }
          playStoreUrl={appStoreLinksResp.data?.googlePlayStoreDeeplink?.url}
          appStoreUrl={appStoreLinksResp.data?.appleAppStoreDeeplink?.url}
        />
      </Stack>

      {!!productsPanel && (
        <ProductsPanel
          products={productsPanel
            .filter(({ name }) => {
              switch (name) {
                case 'super':
                  return showSuperPanel;
                case 'nova':
                  return true;
                default:
                  return true;
              }
            })
            .map((product) => ({
              name: product.name ?? '',
              title: product.title ?? '',
              description: <RichText render={product.richdescription} />,
              signupButtonText: product.signupbuttontext ?? '',
              signupButtonUrl: PrismicLink.url(product.signupbuttonurl),
              signupButtonTarget: product.signupbuttonurl
                ? ((product.signupbuttonurl as _ExternalLink)
                    .target as HTMLAttributeAnchorTarget)
                : undefined,
            }))}
        />
      )}

      <Divider color="neutral.030" />

      <PanelSection>
        <ReviewsPanel
          reviews={reviewsPanel?.reviews?.reduce<ReviewCardProps[]>(
            (accumulator, review) => {
              return review.name && review.date && review.review
                ? [
                    ...accumulator,
                    {
                      name: review.name,
                      date: review.date,
                      review: review.review,
                    },
                  ]
                : accumulator;
            },
            [],
          )}
        />
      </PanelSection>

      {showLearnPanel && learnPanel && (
        <LearnPanel
          title={learnPanel.title}
          description={learnPanel.description}
          posts={posts}
        />
      )}
    </Box>
  );
};

export default withHeroPageLayout()(HomePage);
